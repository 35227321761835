import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/home/runner/work/css/css/src/docs/node_modules/gatsby-theme-docz/src/base/Layout.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h1 {...{
      "id": "dropdown"
    }}>{`Dropdown`}</h1>
    <p>{`Toggle contextual overlays for displaying lists of links and more. To change the opening direction, just add the attribute `}<inlineCode parentName="p">{`data-dropdown-position`}</inlineCode>{` like `}<inlineCode parentName="p">{`data-dropdown-position='left top'`}</inlineCode></p>
    <div className='box'>
  <strong>Attention</strong>: the use of the components needs the <span className='pink'>squid.js</span> included in the project
    </div>
    <div className='box'>
  <strong>Attention + 1</strong>: Probably the interaction via Javascript should not work well in SPA applications (independent of the framework React, Angular, Vuejs, etc). For now we have no implementation but we will create in the future, for now just use the styles and create your own implementation
    </div>
    <button className="button button-pink mb-3 display-block" style={{
      "width": "200px"
    }} type="button" data-dropdown>
  Click Here <i className="far fa-chevron-down"></i>
    </button>
    <ul className="dropdown">
  <li>
    <a href="#" onClick={() => false}>
      Link
    </a>
  </li>
  <li className='px-3 mb-3'>
    Sample Text
  </li>
  <li className='px-3'>
    <label htmlFor='input-text-dropdown'>
      Label
    </label>
    <input style={{
          "width": "100%"
        }} className='display-block input' type='text' name='input-text-dropdown' id='input-text-dropdown' placeholder='Placeholder' />
  </li>
  <li>
    <hr className="dropdown-divider" />
  </li>
  <li>
    <button type='button' onClick={() => false}>
      Button
    </button>
  </li>
  <li>
    <div className='dropdown-plain'>
      <h6>
        I`m a plain dropdown
      </h6>
      <p>
        This is a paragraph
      </p>
    </div>
  </li>
    </ul>
    <pre><code parentName="pre" {...{
        "className": "language-html"
      }}>{`<button
  type="button"
  data-dropdown
>
  <!-- Button content data-dropdown is required -->
  <!-- data-dropdown-position to change where dropdown if opened. Values: right bottom, right top, left bottom, left top -->
</button>
<ul class="dropdown">
  <li>
    <!-- Common Item Like <a> or <button> or forms -->
  </li>
  <li>
    <!-- Custom HR -->
    <hr class="dropdown-divider" />
  </li>
  <li>
    <div class='dropdown-plain'>
      <!-- Plain HTML -->
    </div>
  </li>
</ul>
`}</code></pre>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      